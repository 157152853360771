import React from "react";
import useRuleDiff from "./useRuleDiffs"
import {useRuleContent} from "./useRuleContent";
import {ColumnLayout, Container, Header, Table, Tabs} from "@amzn/awsui-components-react";
import {RuleFile, Sheet} from "./RuleContent";

export function RuleContentPanel() {
  const ruleDiff = useRuleDiff();
  const authoritativeRuleContent = useRuleContent(ruleDiff.authoritativeRuleIds);
  const candidateRuleContent = useRuleContent(ruleDiff.candidateRuleIds);

  function getRuleFileContent(ruleFiles: RuleFile[]) {
    return ruleFiles.map(ruleFile => {
      return getRuleFileTabs(ruleFile);
    })
  }

  function getRuleFileTabs(ruleFile: RuleFile) {
    const tableTabs = ruleFile.sheets.map(sheet => {
      return getRuleFileSheetTableTab(sheet);
    })

    return (
      <Container header={
        <Header
          variant="h3"
        >
          {ruleFile.id}
        </Header>
      }
      >
        <Tabs tabs={tableTabs}/>
      </Container>
    )
  }

  function getRuleFileSheetTableTab(ruleSheet: Sheet) {
    if (!ruleSheet.header) {
      return null;
    }
    let columnDefinitions = ruleSheet.header.map((column, idx) => {
      return {
        id: column,
        header: column,
        cell: item => {
          return item.columns[idx] || "Rule Not Found"
        }
      }
    })

    if (columnDefinitions.length === 0) {
      columnDefinitions = [
        {
          id: "headerNotFound",
          header: "Rule File Not Found",
          cell: _item => {
            return "Rule File Not Found"
          }
        }
      ]
    }

    const rowHeader = {
      id: "row",
      header: "#",
      cell: item => {
        const itemIdSplit = item.id.split('-')
        return itemIdSplit[itemIdSplit.length - 1]
      }
    }

    columnDefinitions.unshift(rowHeader)

    const table = (
      <Table items={ruleSheet.rules} columnDefinitions={columnDefinitions}/>
    )

    return {
      label: ruleSheet.id,
      id: ruleSheet.id,
      content: table
    }
  }

  return (
    <ColumnLayout columns={2}>
      <Container
        header={
          <Header
            variant="h2"
          >
            Authoritative
          </Header>
        }
      >
        {getRuleFileContent(authoritativeRuleContent)}
      </Container>
      <Container
        header={
          <Header
            variant="h2"
          >
            Candidate
          </Header>
        }
      >
        {getRuleFileContent(candidateRuleContent)}
      </Container>
    </ColumnLayout>
  )
}