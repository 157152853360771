import {useEffect, useState} from "react";
import {RuleContentReader} from "../../../excel/RuleContentReader";
import {Rule, RuleFile, Sheet} from "./RuleContent";

export function useRuleContent(ruleIds: string[]): RuleFile[] {
  const ruleContentReader = RuleContentReader.create();
  const [ruleContent, setRuleContent] = useState<RuleFile[]>([]);

  useEffect(() => {
    const read = async () => {
      setRuleContent(await ruleContentReader.readRuleFiles());
    }
    read();
  }, [])

  function extractRuleIdComponents(ruleId: string) {
    const tokens = ruleId.split("-");
    console.log("tokens: " + JSON.stringify(tokens));
    if (tokens.length === 5) {
      return tokens;
    } else if (tokens.length > 5) {
      // Handle case where Sheet name includes a "-"
      let sheetName = tokens.slice(3, -1).join('-');
      return [...tokens.slice(0, 3), sheetName, tokens[tokens.length - 1]];
    } else {
      throw new Error("RuleId is malformed, not all components found. RuleId: " + ruleId);
    }

  }

  function findInterestedRuleContent(ruleIds: string[], ruleContent: RuleFile[]): RuleFile[] {
    const ret: RuleFile[] = []

    ruleIds.forEach((ruleId) => {
      const tokens = extractRuleIdComponents(ruleId);
      const ruleFileId = tokens[0] + "-" + tokens[1] + "-" + tokens[2];
      const ruleSheetId = tokens[3];

      const filteredRuleFile = findRuleFile(ret, ruleFileId, false);
      if (filteredRuleFile) {
        const filteredRuleSheet = findRuleSheet(filteredRuleFile, ruleSheetId, false);

        if (filteredRuleSheet) {
          const ruleFile = findRuleFile(ruleContent, ruleFileId);
          const ruleSheet = findRuleSheet(ruleFile, ruleSheetId);
          const rule = findRule(ruleSheet, ruleId);

          filteredRuleSheet.rules.push(rule);
        } else {
          const ruleFile = findRuleFile(ruleContent, ruleFileId);

          const ruleSheet = findRuleSheet(ruleFile, ruleSheetId);
          const clonedRuleSheet: Sheet = {
            ...ruleSheet,
            rules: []
          }

          const rule = findRule(ruleSheet, ruleId);
          filteredRuleFile.sheets.push(clonedRuleSheet);
          clonedRuleSheet.rules.push(rule);
        }
      } else {
        const ruleFile = findRuleFile(ruleContent, ruleFileId);
        const clonedRuleFile: RuleFile = {
          ...ruleFile,
          sheets: []
        }

        const ruleSheet = findRuleSheet(ruleFile, ruleSheetId);
        const clonedRuleSheet: Sheet = {
          ...ruleSheet,
          rules: []
        }

        const rule = findRule(ruleSheet, ruleId);
        clonedRuleFile.sheets.push(clonedRuleSheet);
        clonedRuleSheet.rules.push(rule);
        ret.push(clonedRuleFile)
      }
    })

    sortRuleContent(ret);

    return ret;
  }

  function sortRuleContent(ruleFiles: RuleFile[]) {
    ruleFiles.sort(sortRuleRules);
    ruleFiles.forEach((ruleFile) => {
      ruleFile.sheets.sort(sortRuleSheets);
      ruleFile.sheets.forEach((sheet) => {
        sheet.rules.sort(sortRules);
      })
    })
  }

  function sortRuleRules(ruleFileA: RuleFile, ruleFileB: RuleFile) {
    return ruleFileA.id.localeCompare(ruleFileB.id);
  }

  function sortRuleSheets(sheetA: Sheet, sheetB: Sheet) {
    return sheetA.id.localeCompare(sheetB.id);
  }

  function sortRules(ruleA: Rule, ruleB: Rule) {
    const ruleAIdSplit = ruleA.id.split('-')
    const ruleBIdSplit = ruleB.id.split('-')
    return parseInt(ruleAIdSplit[ruleAIdSplit.length - 1]) - parseInt(ruleBIdSplit[ruleBIdSplit.length - 1]);
  }

  function findRuleFile(ruleContent: RuleFile[], ruleFileId: string, returnEmpty = true) {
    const emptyRuleFile = {id: ruleFileId, sheets: []};
    return ruleContent.find((ruleFile) => ruleFile.id === ruleFileId ||
      // TODO: This is a temp fix to handle `Global-` / `<Realm>-` prefix and other delimiters
      (ruleFile.id && ruleFile.id.split('-').slice(1).join('-').replace(/[^0-9a-z]/gi, '') === ruleFileId.replace(/[^0-9a-z]/gi, ''))
    ) || (returnEmpty && emptyRuleFile)
  }

  function findRuleSheet(ruleFile: RuleFile, sheetId: string, returnEmpty = true) {
    const emptySheet = {id: sheetId, header: [], rules: []};
    if (!ruleFile) {
      return returnEmpty && emptySheet;
    }
    return ruleFile.sheets.find((sheet) => sheet.id === sheetId) || returnEmpty && emptySheet;
  }

  function findRule(ruleSheet: Sheet, ruleId: string, returnEmpty = true) {
    const emptyRule = {id: ruleId, columns: []};
    if (!ruleSheet) {
      return returnEmpty && emptyRule;
    }
    return ruleSheet.rules.find((rule) => rule.id === ruleId ||
      // TODO: This is a temp fix to handle `Global-` / `<Realm>-` prefix and other delimiters
      (rule.id && rule.id.split('-').slice(1).join('-').replace(/[^0-9a-z]/gi, '') === ruleId.replace(/[^0-9a-z]/gi, ''))
    ) || (returnEmpty && emptyRule)
  }

  return findInterestedRuleContent(ruleIds, ruleContent);
}